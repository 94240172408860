import React from "react";
import { BrowserHeader } from "../components/ComponentWithSidebar";
import { LoginPage } from "../pageComponents/loginComponents/parent";

const Login = () => (
  <div>
    <BrowserHeader />
    <LoginPage />
  </div>
);

export default Login;
